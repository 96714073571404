/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~bootstrap/dist/css/bootstrap.min.css";

.scanner-ui {
  display: none
}

.scanner-hide {
  visibility: visible
}

body.qrscanner {
  background-color: transparent
}

body.qrscanner .scanner-ui {
  display: block
}

body.qrscanner .scanner-hide {
  visibility: hidden
}

.modal-time {
  --width: 290px;
  --height: 252px;
  --border-radius: 4px;
}

.modal-datetime {
  --width: 290px;
  --height: 438px;
  --border-radius: 4px;
}

.modal-Camera {
  --height: 400px;
  --width: 600px;
  --border-radius: 4px;
  overflow: hidden;
}

.sorting-bar {
  box-shadow: 0 3px 4px rgb(0 0 0 / 6%);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #9d9fa9;

  .opt-btn {
    float: right;
  }

  ion-select {
    color: white;
    background-color: #ffffff24 !important;
    border: 0;
    width: fit-content;
    max-width: 200px;
    margin: 4px 2px;

    &::part(text) {
      margin-right: 5px;
    }
  }

  .checkbox-item {
    color: white;
    --ripple-color: rgba(0, 0, 0, 0);
    border-radius: 15px;
    display: inline-block;
    height: auto;
    --background: #ffffff24;

    ion-checkbox {
      --background-checked: #2482c5;
      --border-color-checked: #ffffffba;
      --background: #fdfdfd40;
      --border-color: #0000006b;
      margin-right: 0.5em;
    }
  }
}

/*@media only screen and (min-width: 768px) and (min-height: 768px) {
  .modal-chat-custom {
    --height: 600px;
  }
}
@media only screen and (min-width: 768px) and (min-height: 600px) and (max-height: 767px) {
  .modal-chat-custom {
    --height: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-height: 599px) {
  .modal-chat-custom {
    --height: 100%;
    --width: 100%;
  }
}*/
i {
  font-size: 12px;
}

.deleteAccountAlert {
  .alert-title.sc-ion-alert-md {
    color: red;
  }
}

@media (min-width: 750px) {
  .deleteAccountAlert {
    .alert-wrapper {
      --min-width: 1000px;
    }
  }
}

.imageContainer {
  --width: 100%;
}
.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
  white-space: normal !important;
}
.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
  white-space: normal !important;
}
