// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@use "sass:math";

/* Custom marker & htmlInfoWindow styles */
@function sqrt($r) {
    $x0: 1;
    $x1: $x0;
    @for $i from 1 through 10 {
        $x1: $x0 - math.div(($x0 * $x0 - abs($r)), (2 * $x0));
        $x0: $x1;
    }
    @return $x1;
}
$pinWidth: 33px;
$pinHeightFactor: math.div((1 + sqrt(2)), 2);
$pinHeight: 50px;
$pinColor: #9dd7f7;
$shadowOpacity: .5;
$shadow-size: 25px;
$pulseSize: 50px;
$whiteProgressBG: #fefefe;

.no-scroll {
  --overflow: hidden;
}

.customPopover {
  --min-width: 300px;
}

// Pour pouvoir afficher un title sur les icons de navigations on désactive le pointer event sur les ion icons
ion-list > ion-menu-toggle > div > ion-icon {
  pointer-events: none;
}

.pin-wrap {
    position: absolute;
    width: $pinWidth;
    height: $pinHeight;
    margin-top: -$pinHeight;
    margin-left: math.div(-$pinWidth, 2);
    transform-origin: 50% ($pinHeightFactor * 100%) 0;
}

.pulse {
  position: absolute;
  margin-top: math.div(-$pulseSize, 2);
  margin-left: math.div(-$pulseSize, 2);
  transform: rotateX(55deg);
}
.pulse::after {
  display: block;
  width: $pulseSize;
  height: $pulseSize;
  content: '';
  animation: pulsate 1s ease-out;
  animation-delay: 1.1s;
  animation-iteration-count: infinite;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, $shadowOpacity);
}

.shadow {
  position: absolute;
}
.shadow::after {
  position: absolute;
  left: -100px - math.div($shadow-size, 2);
  display: block;
  width: $shadow-size;
  height: $shadow-size;
  margin-top: math.div(-$shadow-size, 2);
  content: '';
  transform: rotateX(55deg);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, $shadowOpacity) 100px 0 20px;
}

@keyframes pulsate {
    0% {
        transform: scale(.1, .1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.typeTransportMap {
  vertical-align: middle;
  position: absolute;
  font-size: 3.1em;
  left: 10.5px;
  top: 10.5px;
}

.colorTransportMap {
  vertical-align: middle;
  font-size: 6em;
  margin: -6px;
  &.helico {
    color: #379a79;
  }
  &.train {
    color: #734286;
  }
  &.car {
    color: #59bbb9;
  }
  &.airplane {
    color: #f9bb46;
  }
  &.motobike {
    color: #00cbff;
  }
}

.StepProgress {
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  counter-reset: list;

  &-item {
    position: relative;

    //&:not(:last-child) {
    //  padding-bottom: 10px;
    //}


    &::before {
      z-index: 99999;
      display: inline-block;
      content: '';
      counter-increment: list;
      position: absolute;
      left: -8px;
      height: 100%;
      width: 10px;
      margin-top: 33px;
    }

    &.last::before{
      height: 0;
      opacity: 0.4;
    }

    &::after {
      z-index: 99999;
      content: attr(id);
      //content: '';
      list-style: upper-alpha;
      display: inline-block;
      position: absolute;
      top: 0;
      //left: -37px;
      width: 19px;
      height: 18px;
      left: -30px;
      border: 2px solid #CCC;
      border-radius: 50%;
      background-color: #FFF;
      margin-top: 22px;
    }

    &.current {
      &::before {
        z-index: 99999;
        border-left: 5px dashed rgb(157, 157, 162, 60%);
        margin-top: 33px;
      }

      //&.toto::before {
      //  border-left: 5px solid #d0bc02;
      //  margin-top: 15px;
      //}

      &::after {
        z-index: 99999;
        top: 0;
        left: -15px;
        font-size: 14px;
        text-align: center;
        color: white;
        background-color: rgb(157, 157, 162);
        margin-top: 22px;
        border: 0;
        box-shadow: rgb(0 0 0 / 25%) 1px 2px 2px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
      }
    }
  }
}

.popUpTransportText{
  display: flex;
  padding: 8px;
  max-width: 60vw;
  margin-inline-end: 45px;
  flex-direction: column;
  justify-content: center;
  p {
    margin: unset;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap
  }
}

.popUpNoTransportText{
  display: flex;
  justify-content: center;
  padding: 8px;
  max-width: 200px;
  margin-inline-end: 10px;
  flex-direction: column;
  p {
    margin: unset;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap
  }
}

.callMap {
  font-size: 20px;
}

.buttonCall {
  --background: #4c526c;
  height: 34px;
  width: 34px;
}

.buttonCallMap {
  height: 35px;
  width: 35px;
  position: absolute;
  right: 10px;
  top: 9px;
  background: #10dc60;
  padding: 5px;
  border-radius: 50%;
}

/* The htmlInfoWindow styling for transporteurs. */
.popup-bubble-trans {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  max-height: 53px;
  background-color: #f4f5f8;
  border-radius: 30px;
  font-family: sans-serif;
  box-shadow: 3px 2px 4px 1px rgb(0 0 0 / 50%);
  overflow: hidden;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-trans-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-trans-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #f4f5f8;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.4));
}


/* The htmlInfoWindow styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: #f4f5f8;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #f4f5f8;
}

.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}
/* fin  marker & htmlInfoWindow */

.centred-btn-container {
  display: flex;
  justify-content: center;
  &.mobile {
    margin: 0.5em 7.1em 0 7.1em;
    display: none;
    &.not-hidden {
      display: flex;
    }
  }
}
ion-content:not(.bg-white) {
  --ion-background-color: #f4f5f8;
  --color: var(--ion-color-primary);
  height: 100%;
}

.bg-white{
  color: black !important;
}
.bg-transparent {
  --background: transparent !important;
  background: transparent !important;
  --color :  var(--ion-color-primary);
}
.bg-transparent2 {
  --background: transparent !important;
  background: transparent !important;
}

// TODO : déjà modifié, check
.my-custom-modal-css::part(content) {
  //--border-radius: 10px;

  /*--height: 85%;
  @media (max-width: 450px) {
    --width: 90%;
  }
  @media (min-width: 450px) and (max-width: 1000px){
    --width: 60%;
  }
  @media (min-width: 1000px){
    --width: 40%;
  }*/
}

.close-modal-size{
  font-size: 30px;
  color: white;
}

.modalTempPageCss {
  --width: 80%;
  --height: 573px;
  overflow: hidden;
  @media only screen and (max-width: 1175px) {
    --height: 100%;
    --width: 100%;
    .apexcharts-canvas {
      margin-top: calc(25vh - 56px);
    }
  }
}

.sc-ion-picker-md-h{
  --background: white !important;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
div >ion-label{
  position: relative;
  left: 1vw;
  font-size: 0.9em;
}
div>ion-select {
  background-color: white;
  border-radius: 15px;
  border: solid 1px #26658630;
  color: black;
  width: 100%;
  margin: 4px 0 15px 0;
  --padding-start: 10px;
  /*// ne marche pas
  .select-icon {
    color: black;
    padding-right: 3vw;
  }*/
}

.grid-two-input{
  padding:0;
  margin: 10px 0 0;

  >ion-row ion-col{
    padding:0;
  }
  ion-label{
    position: relative;
    color: black;
    font-size: 0.9em;
  }
  ion-row :nth-child(odd){
    padding-right: 6px;
    padding-left: 6px;
  }
  ion-row :nth-child(even){
    padding-left: 6px;
  }
  //ion-col>ion-select {
  //  background-color: white;
  //  border-radius: 15px;
  //  border: solid 1px #26658630;
  //  width: 100%;
  //  margin: 4px 0 15px 0;
  //  --padding-start: 10px;
  //}
}

.mg-top-0{
  margin-top: 0!important;
}

ion-title {
  font-size: 1.3rem;
}

.section-title {
  font-size: 1.2rem;
}
.text{
  font-size: 1rem;
}
.subText {
  font-size: 0.9rem;
}

.mr-2{
  margin-right: 2vw;
}


.sm-p-pic{
  border: 1px solid white;
}

.checkboxes{
  --background: var(--ion-color-tertiary);
  --border-color : var(--ion-color-primary);
  --checkmark-color : white;
}

.butt-btn-left {
  box-sizing: border-box;
  float: left;
  font-size: 0.8rem;
}
.butt-btn-mid {
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  text-overflow: ellipsis;
  justify-content: center;
}
.butt-btn-right {
  box-sizing: border-box;
  float: right;
  font-size: 0.8rem;
}


.c-orange{
  background: #fba51a;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
}


.content-primary {
  color: var(--ion-color-primary);
}


.NotifPopover {
  --backdrop-opacity: 0.4;
  &::part(content) {
    --width: 30%;
    overflow: visible;
    border: 2px solid var(--ion-color-tertiary)8c;
    border-radius: 10px;
    min-height: calc(100% - 60px) !important;
    @media (max-width: 549px) {
      --width: 92%;
    }
    @media (min-width: 550px) and (max-width: 1000px){
      --width: 75%;
    }
    @media (min-width: 1000px){
      --width: 30%;
    }
  }
  &::part(arrow){
    --background: #ffffff99;
  }

}

/** Ionic CSS Variables **/
:root {
  --sm-font-size: 0.9rem;
  --md-font-size: 1rem;
  --lg-font-size: 1.2rem;
  /** primary (Dark grayish blue) **/
  --ion-color-primary: #32374e;
  --ion-color-primary-rgb: 50,55,78;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #2c3045;
  --ion-color-primary-tint: #474b60;

  /** secondary (bright-blue)
   Couleur pour l'agenda **/
  --ion-color-secondary: #3880ff;
  --ion-color-secondary-rgb: 84,165,204;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4a91b4;
  --ion-color-secondary-tint: #65aed1;

  /** tertiary  **/
  --ion-color-tertiary: #1c213b;
  --ion-color-tertiary-rgb: 28,33,59;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #191d34;
  --ion-color-tertiary-tint: #33374f;

  /** success **/
  --ion-color-success: #45b591;
  --ion-color-success-rgb: 69,181,145;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #3d9f80;
  --ion-color-success-tint: #58bc9c;

  /** warning **/
  --ion-color-warning: #fb9f00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d28500;
  --ion-color-warning-tint: #ffce68;

  /** danger **/
  --ion-color-danger: #ed5252;
  --ion-color-danger-rgb: 237,82,82;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #d14848;
  --ion-color-danger-tint: #ef6363;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-dark-grayish-blue: #5e6275;
  --ion-color-dark-grayish-blue-rgb: 94,98,117;
  --ion-color-dark-grayish-blue-contrast: #ffffff;
  --ion-color-dark-grayish-blue-contrast-rgb: 255,255,255;
  --ion-color-dark-grayish-blue-shade: #535667;
  --ion-color-dark-grayish-blue-tint: #6e7283;

  /** dark-cyan **/
  --ion-color-dark-cyan: #266586;
  --ion-color-dark-cyan-rgb: 38,101,134;
  --ion-color-dark-cyan-contrast: #ffffff;
  --ion-color-dark-cyan-contrast-rgb: 255,255,255;
  --ion-color-dark-cyan-shade: #215976;
  --ion-color-dark-cyan-tint: #3c7492;

  /** bright-blue **/
  --ion-color-bright-blue: #002e76;
  --ion-color-bright-blue-rgb: 0,46,118;
  --ion-color-bright-blue-contrast: #ffffff;
  --ion-color-bright-blue-contrast-rgb: 255,255,255;
  --ion-color-bright-blue-shade: #002868;
  --ion-color-bright-blue-tint: #1a4384;
  --ion-color-bright-blue-transparent: rgba(101, 174, 209, 0.6);
  --ion-color-bright-blue-transparent-opac: #7f96ba;

  /** pale-blue **/
  --ion-color-pale-blue: #b5e6ff;
  --ion-color-pale-blue-rgb: 181,230,255;
  --ion-color-pale-blue-contrast: #000000;
  --ion-color-pale-blue-contrast-rgb: 0,0,0;
  --ion-color-pale-blue-shade: #9fcae0;
  --ion-color-pale-blue-tint: #bce9ff;

  /** light-blue **/
  --ion-color-light-blue: #9fc5f8;
  --ion-color-light-blue-rgb: 159,197,248;
  --ion-color-light-blue-contrast: #000000;
  --ion-color-light-blue-contrast-rgb: 0,0,0;
  --ion-color-light-blue-shade: #8cadda;
  --ion-color-light-blue-tint: #a9cbf9;

  /** bright-green **/
  --ion-color-bright-green: #17a489;
  --ion-color-bright-green-rgb: 23,164,137;
  --ion-color-bright-green-contrast: #ffffff;
  --ion-color-bright-green-contrast-rgb: 255,255,255;
  --ion-color-bright-green-shade: #149079;
  --ion-color-bright-green-tint: #2ead95;
  --ion-color-bright-green-transparent: rgba(46, 173, 149, 0.6);
  --ion-color-bright-green-transparent-opac: #82cebf;

  /** grey **/
  --ion-color-grey: #dddddd;
  --ion-color-grey-rgb: 221,221,221;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0,0,0;
  --ion-color-grey-shade: #c2c2c2;
  --ion-color-grey-tint: #e0e0e0;

  --ion-color-selected: #ebe6e9;
  --ion-color-selected-rgb: 235,230,233;
  --ion-color-selected-contrast: #000000;
  --ion-color-selected-contrast-rgb: 0,0,0;
  --ion-color-selected-shade: #cfcacd;
  --ion-color-selected-tint: #ede9eb;

  /* labo cyan*/
  --ion-color-labo: #1bc6de;
  --ion-color-labo-rgb: 195,221,201;
  --ion-color-labo-contrast: #000000;
  --ion-color-labo-contrast-rgb: 0,0,0;
  --ion-color-labo-shade: #acc2b1;
  --ion-color-labo-tint: #c9e0ce;
  --ion-color-labo-transparent-opac: #94e2ea;

  /* Global colors */
  --global-green: #45b591;
  --global-red: #ed5252;
}

.ion-color-bright-green {
  --ion-color-base: var(--ion-color-bright-green);
  --ion-color-base-rgb: var(--ion-color-bright-green-rgb);
  --ion-color-contrast: var(--ion-color-bright-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bright-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-bright-green-shade);
  --ion-color-tint: var(--ion-color-bright-green-tint);
}
.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-light-blue {
  --ion-color-base: var(--ion-color-light-blue);
  --ion-color-base-rgb: var(--ion-color-light-blue-rgb);
  --ion-color-contrast: var(--ion-color-light-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue-shade);
  --ion-color-tint: var(--ion-color-light-blue-tint);
}
.ion-color-selected {
  --ion-color-base: var(--ion-color-selected);
  --ion-color-base-rgb: var(--ion-color-selected-rgb);
  --ion-color-contrast: var(--ion-color-selected-contrast);
  --ion-color-contrast-rgb: var(--ion-color-selected-contrast-rgb);
  --ion-color-shade: var(--ion-color-selected-shade);
  --ion-color-tint: var(--ion-color-selected-tint);
}

.ion-color-labo {
  --ion-color-base: var(--ion-color-labo);
  --ion-color-base-rgb: var(--ion-color-labo-rgb);
  --ion-color-contrast: var(--ion-color-labo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-labo-contrast-rgb);
  --ion-color-shade: var(--ion-color-labo-shade);
  --ion-color-tint: var(--ion-color-labo-tint);
}

.ion-color-bright-blue {
  --ion-color-base: var(--ion-color-bright-blue);
  --ion-color-base-rgb: var(--ion-color-bright-blue-rgb);
  --ion-color-contrast: var(--ion-color-bright-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bright-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-bright-blue-shade);
  --ion-color-tint: var(--ion-color-bright-blue-tint);
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary);
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade);
  --ion-color-tint: var(--ion-color-tertiary-tint);
}


.tutorial-pagination {
  position: fixed;
  bottom: 0;
  text-align: center;
  @media (min-width: 500px) and (min-height: 700px) {
    padding-bottom: 30px;
  }
  @media (max-width: 500px) {
    padding-bottom: 10px;
  }
}

.centrerBoutonPoche {
  justify-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.centrerBouton {
  @media (min-height: 650px) {
    //margin-top: 30px;
  }
  @media (max-height: 650px) {
    //margin-top: 15px;
  }
  justify-content: center;
  display: flex;
}

.validerBouton {
}

.special {
  --background: none !important;
  background-color: white;
  border-radius: 15px;
  border: solid 1px #26658630;
  margin: 1rem auto;
  @media (max-width: 650px) {
    width: 80%;
  }
  @media (min-width: 650px) and (max-width: 1250px){
    width: 50%;
  }
  @media (min-width: 1250px){
    width: 25%;
  }
}

.cursorChange{
  cursor: pointer;
}

.nav-bar-background {
  --background: var(--ion-color-tertiary);
  --color: white;
}

.nav-bar-vertical-background {
  overflow-y: hidden;
  --background: var(--ion-color-primary) !important;
}

.bg-progressCard-header{
  color: #2a2a2a;
  padding: 10px;
}


.white-bg {
  background: $whiteProgressBG;
}

.no-padding {
  padding: 0 !important;
}

.no-wrap-text {
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bold-blue {
  font-weight: bold;
  color: black;
}


app-pmo, app-pmo-archive, app-gestion, app-personnel, app-transport, app-mon-compte, app-invites {
  @media (max-width: 576px) {
    margin-bottom: 55px !important;
  }
}

ion-back-button {
  color:white;
}

.autocomplete {
  width: 100%;
  border-radius: 30px;
  background: #f8f8f9;
  border: 0;
  margin: 0;
  padding: 10px;
  outline: 1px solid #26658630;
}

.isolated-item > app-autocomplete-component > form > input.autocomplete {
  margin-top: -2px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
  background: white;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
}

.autocomplete::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.4;
}

ion-searchbar {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  flex-direction: unset !important;
  --background: #ffffffe8 !important;
}

// CSS POUR LA CARTE ( non declarable dans le scss du component )

.dottedMarkerLabel {
  position: absolute;
  top: 42px;
  right: -4.5px;
}

.cluster img {
  width: 30px;
  height: auto;
  top: -9px;
  left: 10.5px;
}

.dottedMarker{
  height: 20px;
  width: 20px;
  background-color: #f0f0f0;
  border: 1.5px solid black;
  border-radius: 50%;
  text-align: center;
  line-height:20px;
  position: absolute;
  left: -10px;
  top: -10px;
}

.infoWindowContent {
  padding: 10px;
  text-align: center;
}
.infoWindowContentPlane {
  padding: 10px;
  text-align: center;
  font-size: 13px;
}

.infoWindowTransportContent {
  min-width: 180px;
  display: inline-flex;
  overflow: hidden;
}

.infoWindowHeader {
  background-color: #266586;
  min-width: 180px;
  padding: 10px;
  color: white;
  font-weight: bold;
  text-align: center;
}
.infoWindowHeaderPlane {
  background-color: #266586;
  min-width: 180px;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.pin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $pinWidth;
  height: $pinHeight;
  margin-top: math.div(-$pinHeight, 2);
  margin-left: math.div(-$pinWidth, 2);
  transform-origin: 50% ($pinHeightFactor * 100%) 0;
}
.pin::after {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: $pinWidth;
  height: $pinHeight;
  content: '';
  transform: rotateZ(-45deg);
  border: 20px solid $pinColor;
  border-radius: 50% 50% 50% 50%;
}
.pin::before {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: $pinWidth;
  height: $pinHeight;
  content: '';
  transform: rotateZ(-45deg);
  border: 18px solid darken($pinColor, 10%);
  border-radius: 50% 50% 50% 0;
;
}

.typeTransport {
  vertical-align: middle;
  position: absolute;
  font-size: 1.7em;
  left: 11px;
  top: 17px;
  &.airliner {
    top: 11px;
  }
  &.train {
    top: 11px;
  }
}

.colorTransport {
  vertical-align: middle;
  font-size: 3em;
  &.helico {
    color: #379a79;
  }
  &.train {
    color: #734286;
  }
  &.car {
    color: #59bbb9;
  }
  &.airplane {
    color: #f9bb46;
  }
  &.motobike {
    color: #00cbff;
  }
}

.tranp-item {
  margin-top: 0;
  --color: var(--ion-color-primary);
  * {
    --color: black;
    --border-color: var(--ion-color-primary-contrast);
  }
}

.finish-it {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
}

// Scroll bar add .sc2 after class with overflow

.sc2::-webkit-scrollbar, textarea::-webkit-scrollbar {
  top: 5px;
  width: 5px;
  height: 5px;
}

.sc2::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.sc2::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  background-color: rgba(17, 23, 26, 0.38);
  border-radius: 10px;
}

.alert-icon-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0 5px;
  //border: 1px solid #80808069;
  margin: 3px 20px 3px 0;
  background: #ffffff;
  box-shadow: 2px 2px 3px #00000047;
  border: solid #f97676;
  font-size: 21px;
  display: flex;
  align-items: center;
  &.kidney {
    &-left {
      border: solid #db3b3b;
      color: #db3b3b;
    }
    &-right {
      border: solid #23ac43;
      color: #23ac43;
    }
  }
  &.thoracic {
    border: solid #2482c5;
    color: #2482c5;
  }
  &.abdominal {
    border: solid #fbbc05;
    color: #fbbc05;
  }
  img {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

.finish-it-wrapper {
  position: relative;
  left: calc(4vw + 12px);
  top: 0;
  margin-bottom: -25px;
  z-index: 9;
  padding: 2px 5px;
  box-shadow: 1px 1px 3px 1px rgb(103 103 103 / 20%);
  border-radius: 6px;
  background: #fefefe;
  width: fit-content;
  display: flex;
  align-items: center;
}
.suivi {
  left: 30px !important;
  top: 5px !important;
}

.pulsing-point {
  flex-grow: 0;
  flex-shrink: 0;
  background: #2593c9;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  margin-right: 0.5em;
  animation: pulsing-it 1s infinite;
  &-global {
    background: #2593c9;
    border-radius: 100%;
    margin-right: 0.5em;
    animation: pulsing-it 1s infinite;
    height: 12px;
    width: 12px;
  }
}

@keyframes pulsing-it {
  0% {
    box-shadow: 0 0 0 0 rgba(36, 130, 197, 0.7);
  }

  70% {
    box-shadow: 0 0 0 5px rgba(36, 130, 197, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(36, 130, 197, 0);
  }
}

.text-IT {
  color: #2593c9;
  font-weight: bold;
  font-size: 20px;
  &-global {
    color: #2593c9;
    font-weight: bold;
    font-size: 34px;
  }
  &-pmo {
    color: #2593c9;
    font-weight: bold;
    font-size: 16px;
  }
}

.ion-accordion-toggle-icon {
  color: white !important;
}

.bottom-fab {
  margin: 15px 10px;
  --background: #41465a;
  --color: white;
  width: 60px;
  height: 60px;

  ion-icon {
    font-size: 28px;
  }
}
.small-text {
  font-size: 13px;
}

.empty-wrapper {
  text-align: center;

  ion-icon {
    font-size: 30vh;
    color: rgba(0, 0, 0, 0.08);
  }

  ion-img {
    opacity: 0.2;
    width: 60%;
    margin: auto;
  }

  span {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 4em;
    margin-top: 2em;
  }
}
